import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from '../../state/GlobalState'
import './Login.scss'
import { eventTrack } from '../../analytics/analytics-utils'

function Login() {
    const [, setRoomName] = useGlobalState('roomName')
    const [room, setRoom] = useState({ roomName: '' })
    const history = useHistory()

    const adjustRoomName = () => {
        return { roomName: room.roomName.replaceAll(' ', '-').toLowerCase() }
    }

    const createNewRoom = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const adjustedRoomName = adjustRoomName()
        setRoomName(adjustedRoomName.roomName)
        fetch(`/api/room/${adjustedRoomName.roomName}`, {
            method: 'POST',
        }).then(() => {
            history.push(`/room/${adjustedRoomName.roomName}`)
            eventTrack('Login page', 'Create new room', 'Button')
        })
    }

    return (
        <div className="flexForm">
            <div className="box">
                <div className="login-header">
                    <h1 className="title">Lean Latte</h1>
                    <p className="tag-line">
                        Suggest some topics, vote your favorites, discuss. Drink
                        coffee.
                    </p>
                </div>
                <form onSubmit={createNewRoom}>
                    <input
                        required
                        type="text"
                        data-testid="createRoomName"
                        value={room.roomName}
                        placeholder="Type a name for your room"
                        className="child create-input"
                        onChange={(event) =>
                            setRoom({
                                ...room,
                                roomName: event.target.value,
                            })
                        }
                    />
                    <button
                        className="button child create-button"
                        type="submit"
                        data-testid="createRoomButton"
                    >
                        Create
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Login
